.SearchFilterPost {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #fff;
  padding: 2px;
  padding-left: 30px;
  border-radius: 100px;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.08);
  .btn {
    width: 139px;
  }
  .inputs-holder {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .inp-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    &:not(:first-child) {
      padding-left: 16px;
      &::before {
        content: "";
        width: 1px;
        height: 16px;
        background: #f1f0ff;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.type-search {
      flex: 0 0 60%;
      max-width: 60%;
    }
    &.location-search {
      flex: 0 0 40%;
      max-width: 40%;
    }
    .form-control {
      border: 0;
      padding: 0;
      box-shadow: none;
    }
  }
}

.FilterPost {
  border: 1px solid #eff0f1;
  border-radius: 26px;
  margin-bottom: 20px;
  transition: 0.2s;
  border-radius: 20px;
  .head {
    padding: 12px 24px;
    border-bottom: 1px solid #eff0f1;
  }
  .body-content {
    padding: 12px 24px;
  }
  .sub-filter-title {
    font-weight: 500;
    font-size: 16px;
  }
  .btn {
    font-size: 14px;
    color: $gray;
    box-shadow: none !important;
  }
  .sub-menu-btn {
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
