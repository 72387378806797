.card-main-post {
  border: 1px solid #eff0f1;
  border-radius: 26px;
  margin-bottom: 20px;
  transition: 0.2s;
  &:hover {
    box-shadow: 0px 12px 23px rgba(0, 0, 0, 0.06);
  }
  .icon-wrapper {
    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-right: 9px;
    }
  }
  .title {
    font-weight: 600;
    font-size: 18px;
  }
  .categor {
    &ies {
      display: flex;
    }
    &y-name {
      min-width: 115px;
      background: #f5f5fa;
      border-radius: 4px;
      margin-right: 14px;
      padding: 8px 17px;
    }
  }
}

.crew-member {
  .profile-image {
    margin-bottom: 30px;
    img {
      width: 230px;
      height: 230px;
      border-radius: 100%;
      object-fit: cover;
    }
  }
  .name {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 8px;
  }
  .position {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 8px;
  }
  .info {
    opacity: 0.7;
    margin-bottom: 8px;
  }
}

.about-footer {
  h4 {
    margin-bottom: 6px;
  }
  a {
    font-size: 20px;
    line-height: 30px;
    text-decoration: none;
    color: #333333;
  }
}

.crew-card {
  text-align: center;
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
  }
  .content {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    .member {
      flex: 0 0 50%;
      max-width: 50%;
      img {
        margin-bottom: 16px;
      }
      .name {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 2px;
      }
      .designation {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}
