.main-input {
  margin-bottom: 16px;
  .form-label {
    font-weight: 400;
    font-size: 16px;
    opacity: 0.78;
  }
  input[type="text"],
  select,
  textarea {
    border-color: #eceff6;
    &.form-control,
    &.form-select {
      border-radius: 21px;
      padding: 0 16px;
      min-height: 42px;
    }
  }

  textarea {
    &.form-control {
      padding-top: 8px;
    }
  }
}
.form-select {
  color: #333333;
}
.theme-react-select__control {
  border-color: #eceff6 !important;
}

.ReactTags__tags {
  .ReactTags__tagInput {
    input {
      border-radius: 21px;
      padding: 0 16px;
      min-height: 42px;
      border: 1px solid #eceff6;
      width: 100%;
    }
  }
  .ReactTags__selected {
    .ReactTags__tag {
      border: 1px solid #eceff6;
      border-radius: 100px;
      padding: 4px 8px;
      margin-top: 2px;
      display: inline-block;
      margin-right: 2px;
      .ReactTags__remove {
        background: transparent;
        line-height: 1;
        border: 0;
      }
    }
  }
}

.profile-type {
  h1 {
    font-weight: 500;
    font-size: 32px;
    color: #333333;
  }
  &-card {
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
    min-height: 245px;
    padding: 33px;
    text-align: center;
    position: relative;
    cursor: pointer;
    .name {
      font-size: 18px;
      color: #333b46;
      max-width: 228px;
      margin: 0 auto;
    }
    .active-icon {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}

.form-screen {
  max-width: 963px;
  margin: 0 auto;
  textarea {
    height: 120px;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    margin-bottom: 36px;
  }
  .job-type-wrapper {
    display: flex;
    margin: 0 -5px;
    @media (max-width: $md) {
      justify-content: center;
      flex-wrap: wrap;
    }
    .job-type-action {
      flex: 1 0;
      padding: 0 5px;
      @media (max-width: $md) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
      @media (max-width: $sm) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .renderSalaryEstimates {
    p {
      font-weight: 500;
    }
  }
}

.theme-react-select {
  &__control {
    border-radius: 21px;
    border-radius: 21px !important;
  }
}
