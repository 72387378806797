.modal {
  &-header {
    border-color: #eceff6;
    padding-left: 22px;
    padding-right: 22px;
  }
  &-body {
    padding-left: 22px;
    padding-right: 22px;
  }
  &-content {
    box-shadow: 0px 12px 23px rgba(0, 0, 0, 0.06);
    border-radius: 26px;
  }
  &-title {
    font-weight: 500;
    font-size: 24px;
  }
  &-backdrop {
    background: rgba(23, 24, 26, 0.4);
    &.show {
      opacity: 1;
    }
  }
}
