$primary: #194bfb;
$primary-light: #dde4fe;
$primary-mud: #fab935;
$gray: #5e6370;
$gray-100: #a0a3ab;
$gray-dark: #545558;
$light-gray: #e2e2e2;
$light-gray-100: #f8f8f8;
$light-gray-200: #f9f9f9;

$xxxl: 1499px;
$xxl: 1299.98px;
$xl: 1199.98px;
$lg: 991.98px;
$ml: 860.98px;
$md: 767.98px;
$sm: 575.98px;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "primary-light": $primary-light,
    "primary-mud": $primary-mud,
    "gray": $gray,
    "gray-100": $gray-100,
    "gray-dark": $gray-dark,
    "light-gray": $light-gray,
    "light-gray-100": $light-gray-100,
    "light-gray-200": $light-gray-200,
  ),
  $theme-colors
);
