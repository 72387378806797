.main-header {
  background: $primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px;
  .logo {
    img {
      height: 60px;
    }
  }
  .head-menus {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    li {
      list-style: none;
      position: relative;
      &:not(:last-child) {
        padding-right: 16px;
      }
      &:not(:first-child) {
        padding-left: 16px;
        &::before {
          content: "";
          width: 1px;
          height: 16px;
          background: #fff;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      a,
      button {
        background: transparent;
        border: 0;
        padding: 0;
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.headSearchWrapper {
  position: relative;
  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    top: 0;
    background: $primary;
    z-index: 1;
  }
  & > div {
    position: relative;
    z-index: 2;
  }
}

.sign-up-layout {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .logo {
    position: absolute;
    left: 50px;
    top: 30px;
    img {
      height: 60px;
    }
  }
  .form-control {
    margin-bottom: 16px;
    border-radius: 100px;
  }
  .banner-content,
  .form-content {
    .divider-form {
    }
    // @media (min-width: $lg) {
    min-height: 100vh;
    // }
    @media (max-width: $lg) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
  .divider-form {
    text-align: center;
    position: relative;
    margin: 16px 0;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      z-index: 1;
      background: currentColor;
      height: 1px;
      opacity: 0.5;
    }
    span {
      position: relative;
      z-index: 2;
      background: #fff;
      padding: 0 12px;
    }
  }
  .banner-content {
    padding: 60px 30px;
    background-color: $primary;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 45%;
    max-width: 45%;
    @media (max-width: $lg) {
      display: none;
    }
  }
  .form-content {
    flex: 0 0 55%;
    max-width: 55%;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .form-wrapper {
      max-width: 379px;
      margin: auto;
      text-align: center;
      .title {
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 10px;
      }
      .text {
        color: #718096;
        font-size: 14px;
      }
    }
    .bottom-link {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 20px 30px;
      a {
        color: #718096;
        text-decoration: none;
      }
    }
  }
}
