.btn {
  // border-radius: 100px;
  &.submit {
    height: 50px;
  }
  &-primary-light {
    color: $primary !important;
  }
  &-apply {
    font-weight: 500;
    width: 110.67px;
  }
  &:focus {
    // box-shadow: 0 0 0 0.15rem rgba($primary, 0.25);
  }
  &.full-rounded {
    border-radius: 100px;
  }
  &-outline {
    &-gray {
      border-color: $light-gray;
      background: transparent !important;
      color: $gray !important;
      &:hover {
        border-color: #172545;
      }
    }
  }
  &.social-login-btn {
    border: 1px solid #eceff6;
    width: 100%;
    max-width: 342px;
    height: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
  }

  &-selection {
    width: 100%;
    border: 1px solid #eceff6 !important;
    border-radius: 40px;
    @media (max-width: $md) {
      margin-bottom: 12px;
    }
    &.active {
      color: var(--bs-primary);
      border-color: var(--bs-primary) !important;
      background: rgba(var(--bs-primary-rgb), 0.05);
    }
  }
}
