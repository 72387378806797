.rc-slider {
  height: 20px;
  &-rail {
    background: #f4f4f4;
    height: 10px;
  }
  &-track {
    height: 10px;
    background: $primary;
  }
  &-handle {
    width: 20px;
    height: 20px;
    opacity: 1;
    border: 0;
    box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.14) !important;
  }
}
